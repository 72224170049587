html,
body,
#root {
  height: 100%;
  background: #b2f1dd;
  color: #2c4a43;
  font-family: "DM Serif Display";
}

body {
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.remove-images img {
  display: none;
}

@keyframes swipeIt {
  0% {
    transform: translateX(0px) rotate(0deg);
  }

  10% {
    transform: translateX(35px) rotate(-6deg);
  }

  40% {
    transform: translateX(-100px) rotate(-9deg);
  }

  100% {
    transform: translateX(0px) rotate(0deg);
  }
}

@keyframes idleSwipe {
  0% {
    transform: translateX(0px) rotate(0deg);
  }

  50% {
    transform: translateX(-35px) rotate(-6deg);
  }

  100% {
    transform: translateX(0px) rotate(0deg);
  }
}

.swipe-animation {
  animation: swipeIt 5s linear forwards;
}

.idle-animation {
  animation: idleSwipe 5s linear infinite;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  cursor: pointer;
}

img:hover {
  /* transform: scale(1.03);
  transition: transform 0.1s ease-out; */
}

path {
  fill: transparent;
}

text {
  font-size: 41px;
  fill: white;
}

.confetti {
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 1001;
  pointer-events: none;
  top: 0;
  left: 0;
}

.imagePool {
  display: none;
}
